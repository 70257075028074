/*! ===================================
 *  Author: Nazarkin Roman, Egor Dankov
 *  -----------------------------------
 *  PuzzleThemes
 *  =================================== */

'use strict';

/***********************************************
 * Sticky sidebar feature
 ***********************************************/
(function ($) {

    $('.sp-sidebar.sticky').stick_in_parent({
        offset_top: $('#sp-header').outerHeight() + 30
    });

})(jQuery);